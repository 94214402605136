.container-login{
    width:100%;
    height: 100%;
    background-color: red;
    float:'none';
    margin:auto;
}

.img-login{
    width: 250px;
    margin: 30px 0;
}

.form-login {
    background-color:rgb(149, 148, 148);
    width:300px;
    float:'none';
    margin:auto;
    overflow:hidden;
    position:relative;
    border-radius: 20px;
    box-shadow: -3px 18px 24px -4px rgba(0,0,0,0.75);
    -webkit-box-shadow: -3px 18px 24px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -3px 18px 24px -4px rgba(0,0,0,0.75);
}

.form-login:after {
 content:"";
 display:block;
 position:absolute;
 height:1px;
 width:100px;
 left:20%;
 background:radial-gradient(left, #111, #444, #b6b6b8, #444, #111);
 top:0;
}

.form-login:before {
    content:"";
 display:block;
 position:absolute;
 width:8px;
 height:5px;
 border-radius:50%;
 left:34%;
 top:-7px;
 box-shadow: 0 0 6px 4px #fff;
}

.inset {
    padding:20px; 
}

.form-login h1 {
 font-size:18px;
 color: #fff;
 text-shadow:0 1px 0 black;
 text-align:center;
 padding:15px 0;
 border-bottom:1px solid rgb(114 108 108);
 position:relative;
}

.form-login h1:after {
    content:"";
 display:block;
 width:250px;
 height:100px;
 position:absolute;
 top:0;
 left:50px;
 pointer-events:none;
 transform:rotate(70deg);
 background:radial-gradient(50deg, rgba(255,255,255,0.15), rgba(0,0,0,0));
 
}


.label {
color:#fff;
 display:block;
 padding-bottom:9px;
}

.input-login {
    width:100%;
 padding:8px 5px;
 background-color: #e8eaed;
 border:1px solid #938787;
 box-shadow:
   0 1px 0 rgba(255,255,255,0.1);
 border-radius:0.3em;
 margin-bottom:20px;
}

.p-container {
    padding:0 20px 20px 20px; 
}

.p-container:after {
    clear:both;
 display:table;
 content:"";
}

.p-container span {
 display:block;
 float:left;
 color:#fff;
 padding-top:8px;
}

.submit {
    padding:5px 20px;
 border-radius:0.5em;
 border-color: #fff;
 background-image: linear-gradient(0deg, #be9337, #ccac51);
 color:white;
 float:right;
 font-weight:bold;
 cursor:pointer;
 font-size:13px;
}

.submit:hover {
 box-shadow:
   inset 0 1px 0 rgba(255,255,255,0.3),
   inset 0 -10px 10px rgba(255,255,255,0.1);
}

.animated-gradient {
    animation: animateBg 4s linear infinite;
    background-image: linear-gradient(90deg,#343434,#ae8734,#343434,#ae8734);
    background-size: 300% 100%;
  }
  @keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }
  