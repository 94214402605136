.bg-gradiant{
    background: url("../images/lighting_banner.png");
    background-repeat: no-repeat;
    margin-top: -40px;
    height: 220px;
}

.bg-mapping{
    background: url("../images/bg-mapping.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 660px;
}

.bg-dashboard{
  background: url('../images/img-dashboard/background-header.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-ambassador{
  background: url('../images/img-dashboard/background-ambassador.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-finance{
  background: url('../images/img-finance/bg_finance.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.img-foto-consultant{
  width: 100%;
}

.bg-step3{
  background: url('../images/img-question/ilustrasi_page5-01.png');
  background-repeat: no-repeat;
  background-size: 390px;
  background-position: left 15% bottom 15%;
  height: 300px;
}
  
.img-award{
    width: 300px;
    margin-top: 20px;
    max-height: 200px;
}

.img-model-step{
  height: 430px;
}

#nav-login {
    color: #000 !important;
    font-weight: bold;
    padding: 7px 0px;
    margin-bottom: 5px;
    text-align: center;
    width: 120px !important;
    border-radius: 15px;
    background-image: linear-gradient(0deg, #be9337, #ccac51);
  }

.img-model-prioritas{
    margin-top: -100px;
    margin-bottom: -40px;
    width: 70%;
    float: right;
}

.icon-mapping{
    padding: 30px 0;
    width: 100%;
}

.img-model-mapping{
    margin-top: -80px;
    width: 80%;
}

.img-prioritas{
    margin-top: 20px;
    width: 200px;
    max-height: 200px;
}

.img-card{
    margin-top: -80px;
    width:100%;
}

.img-flag{
    width: 600px;
}

#img-ilustrasi3{
  width: 300px;
}

.clr-white{
    color:white;
}
.clr-black{
    color:black;
}

.clr-red{
    color: #b51927;
}

.clr-gold{
    color: #ae8734;
}

.btn-promo{
    background-color:white;
    color:'black';
    font-weight: bold;
    border: none;
    border-radius:20px; 
    padding:10px;
    width: 180px;
}

.btn-lanjut{
  background-color:#ae8734;
  color:#fff !important;
  font-weight: bold;
  border: none;
  border-radius:20px; 
  padding:8px;
  width: 140px;
}

.btn-step4{
    background-color:white;
    color:rgb(0, 0, 0);
    border: 1px solid black;
    font-weight: bold;
    margin:5px;
    border-radius:20px; 
    padding:6px;
    width: 140px;
}

.btn-step4:hover{
  background-color: #000;
  color: white;
}

.active-btn{
  background-color: #000;
  color: white;
}

.link-start{
  background-color:white;
  color:'black';
  font-weight: bold;
  border: none;
  border-radius:20px; 
  padding:10px 30px;
  width: 180px;
}

.space-detail{
    margin-top: 20px;
    margin-bottom: 40px;
}

.btn-promo:hover {
    background-color:#b51927;
    color: white;
    transition: 0.7s;
    box-shadow: 0 0 0 4px white;
}

.space-line{
    font-size: 1em;
    line-height: 25px;
}

.lh-2{
    line-height: 2px;
}

.lh-4{
    line-height: 15px;
}

.lh-8{
  line-height: 50px;
}

.benefit-box{
    width:100%;
    height: 270px;
    background-color: #b51927;
    padding: 70px;
}
.detail-benefit{
    margin-top: -60px;
    width: 100%;
    background-color: white;
    padding: 50px;
}

.pl-15{
    padding-left: 12px;
}

.pl-50{
    padding-left: 50px;
}

.f-w-bold{
    font-weight: bold;
}

.fs-18{
  font-size: 18px;
}

.fs-24{
  font-size: 24px;
}

.fs-4em{
    font-size: 3.5em;
}

.fs-6em{
  font-size: 5.2em;
}

.price{
    font-size: 18px;
}

.price-text{
    margin-bottom: 17px;
}

.font-label{
    margin-top: 70px;
    font-size: 25px;
    line-height: 20px;
}

.fw-600{
    font-size:5em;
    font-weight: bold;
    line-height: 60px;
}

.margin-tb-20{
  margin:20px 0;
}

.padding-tb-40{
  padding:40px 0;
}

.margin-tb-40{
  margin:40px 0;
}

.icon{
    background-color: #ccac51;
    border-radius: 30px;
    font-size: 10px;
    font-weight: bold;
    padding: 4px;
    color:black;
}

.listing{
    display: flex;
}

.video{
    border-radius: 30px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 70%;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit:cover;
    border-radius: 10px;
  }
  
  .container-galerry {
    display:flex;
    cursor: pointer;
    height: 170px;
    justify-content:center;
    align-items:center;
    padding:5px;
    
  }

  .carousel-item-padding-40-px{
    padding: 5px;
  }
  
  
  .react-multiple-carousel__arrow--right{
    right: calc(1% + 2px) !important;
  }
  
  .react-multiple-carousel__arrow--left{
    left: calc(1% + 2px) !important;
  }

.text-review{
    text-align: center;
    color:black;
    margin:30px 0;
    font-size:2em;
}

.widget-calendly{
  height: 600px;
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
.snip1533 {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #9e9e9e;
    display: inline-block;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 16px;
    margin: 35px 10px 10px;
    max-width: 310px;
    min-width: 300px;
    position: relative;
    text-align: center;
    width: 100%;
    padding:20px;
    height: 320px;
    background-color: #f1efef;
    border-radius: 5px;
    border-top: 5px solid #ccac51;
  }
  
  .snip1533 *,
  .snip1533 *:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
}

.snip1533 figcaption {
    padding: 13% 10% 12%;
  }
  
  .snip1533 figcaption:before {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    color: #ccac51;
    content: "\f10e";
    font-family: 'FontAwesome';
    font-size: 32px;
    font-style: normal;
    left: 50%;
    line-height: 60px;
    position: absolute;
    top: -30px;
    width: 60px;
  }
  
  .snip1533 h3 {
    color: black;
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    margin: 10px 0 5px;
  }
  
  .snip1533 h4 {
    font-weight: 400;
    margin: 0;
    opacity: 0.5;
  }
  
  .snip1533 blockquote {
    font-style: italic;
    font-weight: 300;
    margin: 0 0 20px;
}

.container-event{
    width: 100%;
    height: 450px;
}

.img-event {
    width: 100%;
    height: 100%;
    object-fit:cover;
  }

  .form-select{
    border:none 1px solid white !important;
    padding: 5px !important;
    background-color: white !important;
  }
  
  .form-input{
    border: none !important;
    outline: none !important;
    border-radius: 20px;
    margin: 5px 0;
  }



  .form-profile, .form-profile:active{
    background-color: #16181a;
  }
  
  .form-check{
    color:white;
    font-size: 16px;
    display: flex;
    justify-content: space-evenly;
  }
  
  .form{
      background-color:rgb(149, 148, 148);
    margin-top: 20px;
    margin-bottom: 20px;
    padding:0 30px;
    border-radius: 20px;
    box-shadow: -3px 18px 24px -4px rgba(0,0,0,0.75);
    -webkit-box-shadow: -3px 18px 24px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: -3px 18px 24px -4px rgba(0,0,0,0.75);
  }

  .hr{
    border: 2px dashed white;
  }
  
  .card-payment{
    background:#16181a; 
    border-radius:14px; 
    max-width: 350px;
    position:relative; 
    display:block; 
    margin:auto;
    padding:60px; 
    padding-left:20px; 
    padding-right:20px;box-shadow: 2px 10px 40px black; 
    z-index:999;
}

.logo-card{max-width:50px; margin-bottom:15px; margin-top: -19px;}

.label-payment{display:flex; font-size:10px; color:white; opacity:.4;}

.input-payment{font-family: 'Work Sans', sans-serif;background:transparent; border:none; border-bottom:1px solid transparent; color:#dbdce0; transition: border-bottom .4s;}
.input-payment:focus{border-bottom:1px solid #1abc9c; outline:none;}

.cardnumber{display:block; font-size:20px; margin-bottom:8px; }

.name{display:block; font-size:13px; width: 200px; float:left; margin-bottom:15px;}

.toleft{float:left;}
.ccv{width:50px; margin-top:-5px; font-size:15px;}

.receipt{background: #dbdce0; border-radius:4px; padding:3%; padding-top:200px; max-width:600px; display:block; margin:auto; margin-top:-180px; z-index:-1;}

.col{width:50%; float:left;}
.bought-item{background:#f5f5f5; padding:2px;}
.bought-items{margin-top:-3px;}

.cost{
  color:#be9337;
  font-size: 24px;
  font-weight: bold;
}
.description{font-size: 13px;}
.price{font-size:12px;}
.comprobe{color:#dbdce0;}
.proceed{ 
  position:absolute; 
  transform:translate(170px, 30px);
  width:170px; 
  height: 40px;
  text-align: center;
  border-radius:10px; 
  font-size: 16px;
  background-color:#be9337; 
  border:none;
  color:white;
  cursor: pointer;
}

.img-slide-right{
  -webkit-animation: slideRight 2s ease;
  -moz-animation: slideRight 2s ease;
  animation: slideRight 2s ease;
}

.img-slide-left{
  -webkit-animation: slideLeft 2s ease;
  -moz-animation: slideLeft 2s ease;
  animation: slideLeft 2s ease;
}

.img-slide-top{
  -webkit-animation: slideTop 2s ease;
  -moz-animation: slideTop 2s ease;
  animation: slideTop 2s ease;
}

.text-center{
  text-align: center;
}

.css-input { 
  border-bottom:2px solid black; 
  border-top: none;
  border-left: none;
  border-right: none;
  font-size:27px; 
  width: 400px;
  text-align:center; 
  padding:5px;  
}
.css-input:focus { outline:none; } 

.img-bakat{
  margin-bottom:15px;
  width:100%;
}

.img-bakat:hover, .img-active{
  border: 3px solid #ccac51;
  opacity:0.4
}


.list-scroll{
  height: 400px;
}

.list-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.list-scroll::-webkit-scrollbar
{
	width: 12px;
  border-radius: 10px;
	background-color: #F5F5F5;
}

.pb-2{
  padding:5px 8px !important;
}

.list-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #ccac51;
}



.desc-img{
  text-align: center;
  font-size: 1em;
  line-height: 14px;
}

@keyframes slideRight {
    0% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
        visibility: visible;
    }
  
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@keyframes slideLeft {
  0% {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      visibility: visible;
  }

  100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
  }
}

@keyframes slideTop {
  0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
      visibility: visible;
  }

  100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
  }
}

@media (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
    .padding-20{
      padding:15px;
    }

    .position-step3{
      left: 1vw !important;
      top: 55vh !important;
    }
  }

  .card-profile {
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
    margin:1em;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  }


  .card__image {
    width: 100px;
    border-radius: 50%;
  }
  
  .card__info {
    margin: 1em 0;
    list-style-type: none;
    padding: 0;
      display: flex;
    justify-content: space-around;
  }
  
  .card__info li {
    display: inline-block;
    text-align: center;
    padding: 0.5em;
  }
  
  .card__info__stats {
    color: var(--main-accent-color);
    font-weight: bold;
    font-size: 1em;
    display: block;
  }
  
  .card__info__stats + span {
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .card__text h2 {
    margin-bottom: 0.3em;
    font-size: 1.4em;
  }
  
  .card__text p {
    margin: 0;
    color: #999;
    font-size: 0.95em;
  }
  
  
  