.nav {
    margin-bottom: 15px;
    text-align: center;
    display: block;
  }
  
  .dot {
    cursor: pointer;
    font-size: 50px;
    line-height: 1;
    margin: 0;
    opacity: 0.8;
    text-shadow: none;
    transition: opacity 1s ease, text-shadow 1s ease;
    will-change: opacity, text-shadow;
  }

  .active{
    color: #ae8734;
    opacity: 1;
    text-shadow: 0 0px 8px;
  }